import { useAccount } from 'wagmi';

import { ChainId } from 'lib/chains';
import { getSupportedChainIds } from 'utils/chains';

type UseIsUnsupportedNetworkOptions = {
  supportedChainIds?: ChainId[];
};

export default function useIsUnsupportedNetwork(
  options: UseIsUnsupportedNetworkOptions = {}
): boolean {
  const { chain } = useAccount();
  const supportedChainIds: number[] =
    options.supportedChainIds || getSupportedChainIds();

  if (!chain) {
    return true;
  }

  return !supportedChainIds.includes(chain.id);
}
