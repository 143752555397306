import { Component, ReactNode } from 'react';

import { logger } from 'lib/logger';

interface Props {
  children?: ReactNode;
  renderOnError?: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * @see https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    if (error instanceof Error) {
      error.message = `ErrorBoundary: ${error.message}`;
    }

    logger.error(`ErrorBoundary: ${error?.message}`, error);
  }

  public render() {
    return this.state.hasError
      ? this.props.renderOnError || null
      : this.props.children;
  }
}

export default ErrorBoundary;
