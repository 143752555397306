import { Chain } from 'lib/chains';
import { ENV } from 'utils/env';
import { getNetworkName, getVerboseNetworkName } from 'utils/network';

export const PLEASE_CONNECT = {
  heading: 'Connect your wallet',
  subheading: 'Please connect your wallet to continue.',
};

export const PLEASE_VERIFY = {
  heading: 'Please verify your profile.',
  subheading:
    'Verify your profile via at least one social media account to prove the authenticity of your profile on Foundation.',
};

export const PLEASE_SIGN = {
  heading: 'Sign the message in your wallet to continue',
  headingShort: 'Sign to continue',
  subheading:
    'Foundation uses this signature to verify that you’re the owner of this Ethereum address.',
};

export const getConnectToNetworkCopy = (chain: Chain = ENV.PRIMARY_CHAIN) => {
  const networkName = getNetworkName(chain);
  return `Connect to ${networkName}` as const;
};

export const getPleaseConnectToNetworkToContinueCopy = (
  chain: Chain = ENV.PRIMARY_CHAIN
) => {
  const verboseNetworkName = getVerboseNetworkName(chain);
  return `Please connect to the ${verboseNetworkName} to continue.` as const;
};

export const getChangeNetworkCopy = (chain: Chain = ENV.PRIMARY_CHAIN) => {
  return {
    heading: getConnectToNetworkCopy(chain),
    subheading: getPleaseConnectToNetworkToContinueCopy(chain),
  };
};
