import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Button, { ButtonProps } from 'components/base/Button';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Text, { TextVariants } from 'components/base/Text';
import WorldLogo from 'components/base/WorldLogo';

import { formatBasisPoints } from 'utils/formatters';
import { getPath } from 'utils/router';

import { WorldOverview, WorldSearchResult } from 'types/World';

import WorldHoverCard from './WorldHoverCard';

const Root = styled('div', {
  display: 'flex',
  minWidth: 0,
  gap: '$3',
  alignItems: 'center',
});

const WorldTagText = styled(Text, {
  textAlign: 'left',
  display: 'block',
  truncate: true,
});

WorldTagText.defaultProps = {
  size: 2,
  weight: 'semibold',
  color: 'strong',
};

type WorldTagProps =
  /** Links to the world */
  | {
      type: 'button';
      world: WorldOverview;
      // Note: Only the raised, ghost, and outline variants are currently
      // tested. More changes will be needed if working with other variants.
      variant?: ButtonProps['variant'];
    }
  /** Links to both the world and curator */
  | { type?: 'link'; world: WorldOverview; showCuratorFee?: boolean }
  /** Looks like a link, but has no hover cards or interactive elements */
  | { type: 'static'; world: WorldOverview; showCuratorFee?: boolean }
  /** For rendering in a menu or list */
  | { type: 'list-item'; world: WorldSearchResult };

export default function WorldTag(props: WorldTagProps) {
  const logo = props.world?.imageUrl;
  const name = props.world.name;
  const href = getPath.world.page(props.world.slug);

  if (props.type === 'button') {
    return (
      <WorldHoverCard world={props.world}>
        <NextLink href={href} passHref>
          <Button
            css={{
              paddingY: '$2',
              paddingX: '$3',
              paddingLeft: logo ? '$2' : undefined,
              borderRadius: '$3',
              height: 'auto',
            }}
            as="a"
            variant={props.variant || 'raised'}
          >
            <WorldTagScaffolding
              logo={logo ? <WorldLogo imageUrl={logo} size={4} /> : null}
              name={<WorldTagText>{name}</WorldTagText>}
              fee={null}
            />
          </Button>
        </NextLink>
      </WorldHoverCard>
    );
  }

  if (props.type === 'list-item') {
    return (
      <WorldTagScaffolding
        logo={
          logo ? (
            <WorldLogo imageUrl={logo} size={4} />
          ) : (
            <WorldLogo.Skeleton size={4} />
          )
        }
        name={<WorldTagText>{name}</WorldTagText>}
        fee={null}
      />
    );
  }

  if (props.type === 'static') {
    return (
      <WorldTagScaffolding
        logo={logo ? <WorldLogo imageUrl={logo} size={4} /> : null}
        name={<WorldTagText as="span">{name}</WorldTagText>}
        fee={
          <FeeDetails
            takeRateInBasisPoints={props.world.takeRateInBasisPoints}
            showCuratorFee={props.showCuratorFee}
          />
        }
      />
    );
  }

  return (
    <WorldTagScaffolding
      logo={
        logo ? (
          <WorldHoverCard world={props.world}>
            <NextLink href={href} passHref prefetch={false}>
              <Link>
                <WorldLogo imageUrl={logo} size={4} />
              </Link>
            </NextLink>
          </WorldHoverCard>
        ) : null
      }
      name={
        <WorldHoverCard world={props.world} placement="top" offset={[0, 2]}>
          <NextLink href={href} passHref prefetch={false}>
            <Link variant="strong">
              <WorldTagText as="span">{name}</WorldTagText>
            </Link>
          </NextLink>
        </WorldHoverCard>
      }
      fee={
        <FeeDetails
          takeRateInBasisPoints={props.world.takeRateInBasisPoints}
          showCuratorFee={props.showCuratorFee}
        />
      }
    />
  );
}

type FeeDetailsProps = {
  showCuratorFee?: boolean;
  takeRateInBasisPoints: WorldOverview['takeRateInBasisPoints'];
};

function FeeDetails(props: FeeDetailsProps) {
  const { takeRateInBasisPoints, showCuratorFee } = props;

  const canShowCuratorFee =
    showCuratorFee && typeof takeRateInBasisPoints === 'number';

  if (canShowCuratorFee) {
    return (
      <Flex css={{ gap: '$1', alignItems: 'center' }}>
        <Badge css={{ paddingY: 2 }} size={0} variant="light" mono>
          {formatBasisPoints(takeRateInBasisPoints)}
        </Badge>
      </Flex>
    );
  }

  return null;
}

type WorldTagScaffoldingProps = {
  logo: ReactNode;
  name: ReactNode;
  fee: ReactNode;
};

function WorldTagScaffolding(props: WorldTagScaffoldingProps) {
  const { logo, name, fee } = props;

  return (
    <Root>
      {logo}
      <Box css={{ minWidth: 0 }}>
        <>{name}</>
        <>{fee}</>
      </Box>
    </Root>
  );
}

function WorldTagMini(props: {
  world: WorldOverview;
  size?: TextVariants['size'];
}) {
  const { world, size = 2 } = props;

  return (
    <Box css={{ display: 'flex', minWidth: 0 }}>
      <WorldHoverCard world={props.world}>
        <NextLink href={getPath.world.page(props.world.slug)} passHref>
          <Link
            variant="strong"
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            {world.imageUrl && <WorldLogo imageUrl={world.imageUrl} size={1} />}
            <Text size={size} weight="medium">
              {world.name}
            </Text>
          </Link>
        </NextLink>
      </WorldHoverCard>
    </Box>
  );
}

WorldTag.Mini = WorldTagMini;
