import { LockIcon, RemoveIcon, WarningIcon } from '@f8n/icons';

import { ModeratedModerationStatus } from 'types/Moderation';

type ModerationIconProps = {
  status: ModeratedModerationStatus;
};

const moderationStatusIconMap: Record<ModeratedModerationStatus, JSX.Element> =
  {
    SUSPENDED: <RemoveIcon size={2} />,
    UNDER_REVIEW: <WarningIcon size={2} />,
    TAKEDOWN_REQUESTED: <LockIcon size={2} />,
  };

export default function ModerationIcon(props: ModerationIconProps) {
  return moderationStatusIconMap[props.status];
}
