import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';
import ModerationIcon from 'components/trust-safety/ModerationIcon';

import { ModeratedModerationStatus } from 'types/Moderation';

interface ModeratedBannerProps {
  status: ModeratedModerationStatus;
}

const statusMessageMap: Record<ModeratedModerationStatus, string> = {
  UNDER_REVIEW: 'Under Review',
  TAKEDOWN_REQUESTED: 'Takedown Requested',
  SUSPENDED: 'Suspended',
};

export default function ModeratedBanner(props: ModeratedBannerProps) {
  const { status } = props;

  const message = statusMessageMap[status];

  return (
    <Container>
      <ModerationIcon status={status} />
      <Text
        size={1}
        weight="semibold"
        css={{ color: '$white100', marginLeft: '$2' }}
      >
        {message}
      </Text>
    </Container>
  );
}

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$black70',

  position: 'absolute',
  padding: '$4',
  zIndex: 10,
  right: 0,
  top: 0,

  svg: {
    color: '$white80',
  },
});
