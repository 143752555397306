import { motion, onGrid } from '@f8n/tokens';
import { darkTheme, styled } from '@f8n-frontend/stitches';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  useClientPoint,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import { VariantProps } from '@stitches/react';
import { cloneElement, isValidElement, useState } from 'react';
import { match } from 'ts-pattern';

import ErrorBoundary from 'components/ErrorBoundary';
import Stat from 'components/Stat';

import { useIsHydrated } from 'hooks/use-is-hydrated';

import Flex from './Flex';
import Text from './Text';

const offsetMap = {
  0: onGrid(1),
  1: onGrid(2),
  2: onGrid(4),
} as const;

const TooltipContentRoot = styled('div', {
  backgroundColor: '$black100',
  color: '$white100',
  fontWeight: '$book',
  borderRadius: '$2',
  position: 'relative',
  textAlign: 'center',
  maxWidth: '30ch',

  variants: {
    variant: {
      duo: {
        paddingX: '$3',
        paddingY: '$2',
      },
      stat: {
        [`${Stat.Label}`]: {
          color: '$white70 !important',
          lineHeight: '$0',
        },

        [`${Stat.Value}`]: {
          color: '$white100',
          lineHeight: '$0',
        },
      },
      text: {},
    },
    size: {
      0: {
        fontSize: '$0',
        paddingX: '$2',
        paddingY: '$1',
      },
      1: {
        fontSize: '$1',
        paddingX: '$3',
        paddingY: '$2',
        lineHeight: '$2',
      },
    },
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
    width: {
      small: {
        maxWidth: '22ch',
      },
    },
    backdrop: {
      /** Use this when rendering a tooltip over a very dark background */
      blur: {
        backgroundColor: '$black80',
        /**
         * Inverts the background color, meaning this gets lighter over a dark background
         * but remains dark over a light background. The blur makes it look like a gradient.
         */
        backdropFilter: 'invert(100%) blur(50px)',
      },
    },
  },

  compoundVariants: [
    {
      size: 0,
      variant: 'stat',
      css: {
        paddingX: '$4',
        paddingY: '$3',

        [`${Stat.Label}`]: {
          fontSize: '$0 !important',
        },

        [`${Stat.Value}`]: {
          fontSize: '$2',
        },
      },
    },
    {
      size: 1,
      variant: 'stat',
      css: {
        paddingX: '$5',
        paddingY: '$3',

        [`${Stat.Label}`]: {
          fontSize: '$1 !important',
        },

        [`${Stat.Value}`]: {
          fontSize: '$4',
        },
      },
    },
  ],

  defaultVariants: {
    size: 0,
    align: 'center',
  },
});

type TooltipContentRootProps = VariantProps<typeof TooltipContentRoot>;

const TooltipIcon = styled('div', {
  color: '$black40',
  transition: 'color $1 $ease',

  '@hover': {
    '&:hover': {
      color: '$black100',
    },
  },
});

type Offset = keyof typeof offsetMap;

type StatConfig = {
  label: string;
  value: string | React.ReactElement;
};

/** A max of 2 stat items are allowed. */
type StatItems = [StatConfig] | [StatConfig, StatConfig];

export type TooltipContent =
  | undefined
  | null
  /** Used when you want one line of text */
  | string
  /** Used when you want two lines of text */
  | { type: 'duo'; title: string; message: string }
  /** Used when you want 1-2 stats */
  | { type: 'stat'; items: StatItems };

type BaseTooltipProps = {
  align?: TooltipContentRootProps['align'];
  backdrop?: TooltipContentRootProps['backdrop'];
  children: React.ReactNode;
  content: TooltipContent;
  followCursor?: boolean;
  placement?: Placement;
  size?: TooltipContentRootProps['size'];
  offset?: Offset;
  width?: TooltipContentRootProps['width'];
};

type ControlledTooltipProps = BaseTooltipProps & {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};
type UncontrolledTooltipProps = BaseTooltipProps & { initiallyOpen?: boolean };
type TooltipProps = ControlledTooltipProps | UncontrolledTooltipProps;

function TooltipController(props: TooltipProps) {
  const isControlled = 'open' in props;
  const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>(
    isControlled ? false : Boolean(props.initiallyOpen)
  );

  const open = isControlled ? props.open : uncontrolledOpen;
  const setOpen = isControlled ? props.onOpenChange : setUncontrolledOpen;

  const { context } = useFloating({
    placement: props.placement || 'top',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetMap[props.offset || 1]),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift({ padding: 5 }),
    ],
  });

  const hover = useHover(context, {
    move: false,
    enabled: !isControlled,
  });
  const focus = useFocus(context, { enabled: !isControlled });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const clientPoint = useClientPoint(context, {
    enabled: Boolean(props.followCursor),
  });

  const interactions = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    clientPoint,
  ]);

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: motion.durations[1],
    initial: ({ side }) => {
      return {
        opacity: 0,
        transform: match(side)
          .with('top', () => 'translateY(4px) scale(0.9)')
          .with('bottom', () => 'translateY(-4px) scale(0.9)')
          .with('right', () => 'translateX(-4px) scale(0.9)')
          .with('left', () => 'translateX(4px) scale(0.9)')
          .otherwise(() => ''),
      };
    },
    open: {
      opacity: 1,
      transform: 'translateY(0px) scale(1)',
    },
    common: ({ side }) => {
      return {
        transformOrigin: match(side)
          .with('top', () => 'bottom')
          .with('bottom', () => 'top')
          .with('right', () => 'left')
          .with('left', () => 'right')
          .otherwise(() => ''),
      };
    },
  });

  if (!isValidElement(props.children)) return null;

  return (
    <>
      {cloneElement(
        props.children,
        interactions.getReferenceProps({
          ...props.children.props,
          ref: context.refs.setReference,
          'data-state': context.open ? 'open' : 'closed',
          ...interactions.getReferenceProps(props.children.props),
        })
      )}{' '}
      <FloatingPortal>
        {isMounted && (
          <TooltipContentRoot
            align={props.align}
            backdrop={props.backdrop}
            ref={context.refs.setFloating}
            size={props.size}
            width={props.width}
            variant={
              typeof props.content === 'string' ? 'text' : props.content?.type
            }
            {...interactions.getFloatingProps()}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              visibility: context.x === null ? 'hidden' : 'visible',
              pointerEvents: 'none',
              ...styles,
            }}
          >
            <TooltipContent content={props.content} />
          </TooltipContentRoot>
        )}
      </FloatingPortal>
    </>
  );
}
TooltipController.defaultProps = {
  size: 0,
};

type TooltipContentProps = {
  content: TooltipContent;
};
function TooltipContent(props: TooltipContentProps) {
  const { content } = props;
  if (!content) return null;

  if (typeof content === 'string') {
    return <>{content}</>;
  }

  if (content.type === 'duo') {
    return (
      <TooltipContentDuo title={content.title} message={content.message} />
    );
  }

  if (content.type === 'stat') {
    return <TooltipContentStat items={content.items} />;
  }

  return null;
}

type PropsForTooltipContentType<Type> = Omit<
  Extract<TooltipContentProps['content'], { type: Type }>,
  'type'
>;

function TooltipContentStat(props: PropsForTooltipContentType<'stat'>) {
  const stat1 = props.items[0];
  const stat2 = props.items[1];

  return (
    <Flex css={{ gap: '$6' }}>
      <Stat.Root>
        <Stat.Label>{stat1.label}</Stat.Label>
        <Stat.Value>{stat1.value}</Stat.Value>
      </Stat.Root>
      {stat2 && (
        <Stat.Root>
          <Stat.Label>{stat2.label}</Stat.Label>
          <Stat.Value>{stat2.value}</Stat.Value>
        </Stat.Root>
      )}
    </Flex>
  );
}

function TooltipContentDuo(props: PropsForTooltipContentType<'duo'>) {
  const { title, message } = props;

  return (
    <Flex className={darkTheme} css={{ flexDirection: 'column' }}>
      <Text size={1} weight="medium">
        {title}
      </Text>
      <Text css={{ color: '$black80' }} size={0}>
        {message}
      </Text>
    </Flex>
  );
}

function TooltipControllerWrapped(props: TooltipProps) {
  const isHydrated = useIsHydrated();

  if (isHydrated && props.content) {
    return (
      <ErrorBoundary renderOnError={<>{props.children}</>}>
        <TooltipController {...props} content={props.content} />
      </ErrorBoundary>
    );
  }

  return <>{props.children}</>;
}

const Tooltip = Object.assign(TooltipControllerWrapped, {
  Icon: TooltipIcon,
});

export default Tooltip;
