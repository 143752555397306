import { ApiWorldStats } from 'gql/api/queries/world-stats.generated';

import { WorldStatsWithCreators } from 'types/World';

export const mapApiExhibitionStatsToWorldStats = (
  response: ApiWorldStats
): WorldStatsWithCreators => {
  if (!response) {
    throw new Error('World stats not found');
  }

  const creators = response.creators;
  const stats = response.stats;
  const collectorsCount = response.collectors.totalItems;
  const collectors = response.collectors.items.map((item) => item.user);

  if (!stats) {
    throw new Error('World market stats not found');
  }

  return {
    ...stats,
    creators: creators.items,
    totalCreators: creators.totalItems,
    totalCollectors: collectorsCount,
    collectors,
  };
};
