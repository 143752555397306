import { match } from 'ts-pattern';

import { ChainId, MAINNET_CHAINS, TESTNET_CHAINS } from 'lib/chains';

import { ENV } from './env';
import { isSupportedChainId } from './network';

export const getChainIdOrDefault = <Value extends { chainId: ChainId | null }>(
  value: Value
): ChainId => {
  if (value && value.chainId && isSupportedChainId(value.chainId)) {
    return value.chainId;
  }

  return ENV.PRIMARY_CHAIN_ID;
};

export const getSupportedChainIds = (): ChainId[] => {
  return match(ENV.CHAIN_CATEGORY)
    .with('mainnet', () => [
      MAINNET_CHAINS.mainnet.chainId,
      MAINNET_CHAINS.base.chainId,
    ])
    .with('testnet', () => [
      TESTNET_CHAINS.sepolia.chainId,
      TESTNET_CHAINS.baseSepolia.chainId,
    ])
    .exhaustive();
};
