import { onGridPx } from '@f8n/tokens';
import { darkTheme, styled, fadeInUp } from '@f8n-frontend/stitches';
import { ComponentProps, CSSProperties } from '@stitches/react';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import DarkMode from 'components/DarkMode';
import Pulse from 'components/Pulse';
import Stat from 'components/Stat';
import Dash from 'components/base/Dash';
import Flex from 'components/base/Flex';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import WorldLogo from 'components/base/WorldLogo';
import ModeratedBanner from 'components/cards/shared/ModeratedBanner';

import useWorldStats from 'hooks/queries/api/use-world-stats';
import { formatInteger, formatTotalEthValue } from 'utils/formatters';
import { getDpr, optimizeAsset } from 'utils/imgix';
import { isFlaggedForModeration } from 'utils/moderation';
import { createCanvasBackground } from 'utils/styles';
import { getDynamicWorldStats } from 'utils/worlds';

import { ModerationStatus } from 'types/Moderation';
import { WorldStatsWithCreators } from 'types/World';

const WorldCardRootOverlay = styled('div', {
  position: 'absolute',
  inset: 0,
});

const WorldCardRoot = styled('div', {
  position: 'relative',
  borderRadius: '$1',
  overflow: 'hidden',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100%',
  width: '100%',

  variants: {
    variant: {
      normal: {
        willChange: 'transform',
        transition: 'transform $1 $ease, box-shadow $1 $ease',
        backgroundColor: '$black100',

        'a &, button &': {
          '@hover': {
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '$regular1',
            },
            '&:active': {
              transform: 'translateY(0)',
              boxShadow: '$regular0',
            },
          },
        },
      },
      skeleton: {
        backgroundColor: '$black5',
        [`${WorldCardRootOverlay}`]: {
          display: 'none',
        },
      },
    },
    background: {
      overlay: {
        [`${WorldCardRootOverlay}`]: {
          background: '$overlay',
        },
      },
      blur: {
        [`${WorldCardRootOverlay}`]: {
          background: '$overlay',
          backdropFilter: 'blur(10px)',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'normal',
  },
});

const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',
  color: '$black100',
});

const WorldCardStatsContainer = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: '$6',
  animation: `${fadeInUp} $transitions$2 $transitions$ease forwards`,

  [`${Stat.Root}:not(:last-of-type)`]: {
    paddingRight: '$4',
    marginRight: '$4',
    borderRight: '1px solid $black20',
  },

  '@bp2': {
    bottom: onGridPx(10),
  },
});

const WorldCardContent = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  paddingX: '$5',
  paddingY: '$4',
  height: 'inherit',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 490,
  '@bp0-max': {
    minHeight: 420,
  },
  variants: {
    size: {
      normal: {},
      short: {
        minHeight: 335,
        minWidth: 320,
        maxWidth: 320,
      },
      large: {
        width: 'unset',
        maxWidth: '1000px',
        margin: '0 auto',
      },
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

export type ContentProps = ComponentProps<typeof WorldCardContent>;
type RootProps = ComponentProps<typeof WorldCardRoot>;
export type BackgroundVariants = RootProps['variant'];

const LogoContainer = styled('div');

interface WorldCardScaffoldingProps {
  backgroundImageUrl?: string;
  backgroundBlur?: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  details: React.ReactNode;
  logo: React.ReactNode;
  variant?: BackgroundVariants;
  size: ContentProps['size'];
  moderationStatus: ModerationStatus;
  stats?: WorldStatsWithCreators;
}

function WorldCardScaffolding(props: WorldCardScaffoldingProps) {
  const {
    backgroundImageUrl,
    backgroundBlur,
    cardRef,
    details,
    logo,
    variant,
    size,
    moderationStatus,
    stats,
  } = props;

  const hasBackgroundGrid = !backgroundImageUrl && variant === 'normal';
  const backgroundStyles = getBackgroundStyle({
    backgroundImageUrl,
    size: 'normal',
    variant,
  });
  const isModerated = isFlaggedForModeration(moderationStatus);

  return (
    <WorldCardRoot
      ref={cardRef}
      variant={variant}
      style={backgroundStyles}
      background={backgroundBlur ? 'blur' : 'overlay'}
    >
      {isModerated && <ModeratedBanner status={moderationStatus} />}
      <WorldCardContent size={size}>
        <DetailsContainer
          css={{
            transform: size === 'short' ? 'none' : 0,
          }}
        >
          {logo ? <LogoContainer>{logo}</LogoContainer> : <div />}
          {details}
        </DetailsContainer>
        {stats && <WorldCardStats stats={stats} />}
      </WorldCardContent>
      <WorldCardRootOverlay css={{ opacity: hasBackgroundGrid ? 0.3 : 1 }} />
    </WorldCardRoot>
  );
}

type SimpleSize = 'normal' | 'short' | 'large';

interface BackgroundStyleOptions {
  backgroundImageUrl?: string | null;
  size: SimpleSize;
  variant?: BackgroundVariants;
}

export const getBackgroundStyle = (
  options: BackgroundStyleOptions
): CSSProperties => {
  const { backgroundImageUrl, size, variant } = options;

  if (variant === 'skeleton') {
    return {};
  }

  const optimizedImageUrl = backgroundImageUrl
    ? buildOptimizedBackgroundImageUrl(backgroundImageUrl, size)
    : undefined;

  if (optimizedImageUrl) {
    return {
      backgroundImage: `url(${optimizedImageUrl})`,
    };
  }

  return createCanvasBackground({
    patternBackgroundSize: '72px',
  });
};

const buildOptimizedBackgroundImageUrl = (
  unoptimizedUrl: string,
  size: SimpleSize
) => {
  if (size === 'large') {
    return optimizeAsset(unoptimizedUrl, {
      w: 1400,
      h: 630,
      fit: 'crop',
      dpr: getDpr(),
    });
  }

  return optimizeAsset(unoptimizedUrl, {
    w: 750,
    h: 500,
    fit: 'crop',
    dpr: getDpr(),
  });
};

interface WorldCardDetailsProps {
  name: string;
}

function WorldCardDetails(props: WorldCardDetailsProps) {
  const { name } = props;
  return (
    <Flex center expandVertical>
      <DarkMode>
        <Text
          size={5}
          weight="medium"
          lineHeight={1}
          css={{
            textAlign: 'center',
            wordBreak: 'break-word',
          }}
          color="strong"
        >
          {name}
        </Text>
      </DarkMode>
    </Flex>
  );
}

interface WorldCardBaseProps {
  backgroundImageUrl?: string;
  cardRef?: React.RefObject<HTMLDivElement>;
  imageUrl?: string;
  name: string;
  size?: ContentProps['size'];
  moderationStatus: ModerationStatus;
  stats?: WorldStatsWithCreators;
}

function WorldCardBase(props: WorldCardBaseProps) {
  const {
    backgroundImageUrl,
    cardRef,
    name,
    imageUrl,

    moderationStatus,
    size = 'normal',
    stats,
  } = props;

  return (
    <WorldCardScaffolding
      backgroundImageUrl={backgroundImageUrl || imageUrl}
      backgroundBlur={Boolean(imageUrl && !backgroundImageUrl)}
      cardRef={cardRef}
      logo={imageUrl ? <WorldLogo imageUrl={imageUrl} size={6} /> : null}
      size={size}
      moderationStatus={moderationStatus}
      details={<WorldCardDetails name={name} />}
      variant="normal"
      stats={stats}
    />
  );
}

function SkeletonDetails() {
  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'center' }}>
      <Flex css={{ gap: '$4' }} center expandVertical>
        <Skeleton.Block
          css={{
            width: 200,
            maxWidth: '80%',
            height: 32,
            borderRadius: '$2',
          }}
          opaque
        />
      </Flex>
    </Flex>
  );
}

type WorldCardSkeletonProps = {
  size?: ContentProps['size'];
};

function WorldCardSkeleton(props: WorldCardSkeletonProps) {
  return (
    <WorldCardScaffolding
      logo={<WorldLogo.Skeleton size={8} />}
      details={<SkeletonDetails />}
      variant="skeleton"
      size={props.size}
      moderationStatus="ACTIVE"
    />
  );
}

interface WorldCardConnectedProps extends WorldCardBaseProps {
  id: number;
}

function WorldCardConnected(props: WorldCardConnectedProps) {
  const { backgroundImageUrl, name, imageUrl, moderationStatus, id } = props;

  const cardRef = useRef<HTMLDivElement>(null);
  const cardIntersection = useIntersection(cardRef, {
    rootMargin: '50% 0px 0px 0px',
  });

  const statsQuery = useWorldStats(
    {
      id,
    },
    {
      enabled: cardIntersection?.isIntersecting || false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    }
  );

  return (
    <WorldCard
      cardRef={cardRef}
      imageUrl={imageUrl}
      name={name}
      backgroundImageUrl={backgroundImageUrl}
      moderationStatus={moderationStatus}
      stats={statsQuery.isSuccess ? statsQuery.data : undefined}
    />
  );
}

function CountStat(props: { count: number; label: string }) {
  const { count, label } = props;

  return (
    <HiddenOnStat align="center" bp="mid">
      <Stat.Label>{label}</Stat.Label>
      <Stat.Value>{formatInteger(count)}</Stat.Value>
    </HiddenOnStat>
  );
}

function LiveStat(props: { count: number; label: string }) {
  const { count, label } = props;

  return (
    <HiddenOnStat align="center" bp="mid">
      <Stat.Label>{label}</Stat.Label>
      <Stat.Value
        css={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        <Pulse size={22} />
        {count}
      </Stat.Value>
    </HiddenOnStat>
  );
}

export type WorldStatsVariant = 'highlight-sales' | 'normal';

function WorldCardStats(props: {
  stats: WorldStatsWithCreators;
  variant?: WorldStatsVariant;
}) {
  const { stats, variant } = props;
  const { creators, totalCreators, totalSales } = stats;

  const dynamicWorldStats =
    variant === 'highlight-sales' ? null : getDynamicWorldStats(stats);

  return (
    <WorldCardStatsContainer className={darkTheme}>
      <HiddenOnStat align="center" bp="mobile">
        <Stat.Label>Creators</Stat.Label>
        <Stat.AvatarCountValue
          count={totalCreators}
          users={creators}
          avatarsVariant="blur"
        />
      </HiddenOnStat>
      {dynamicWorldStats &&
        dynamicWorldStats.map((stat, index) => {
          if (stat.type === 'live') {
            return (
              <LiveStat key={index} count={stat.count} label={stat.label} />
            );
          } else {
            return (
              <CountStat key={index} count={stat.count} label={stat.label} />
            );
          }
        })}
      <Stat.Root align="center">
        <Stat.Label>Total Sales</Stat.Label>
        <Stat.Value css={{ whiteSpace: 'nowrap' }}>
          {totalSales ? `${formatTotalEthValue(totalSales)} ETH` : <Dash />}
        </Stat.Value>
      </Stat.Root>
    </WorldCardStatsContainer>
  );
}

const HiddenOnStat = styled(Stat.Root, {
  variants: {
    bp: {
      mobile: {
        '@bp0-max': {
          display: 'none',
        },
      },
      mid: {
        '@bp3-max': {
          display: 'none',
        },
      },
    },
  },
});

const WorldCard = Object.assign(WorldCardBase, {
  Root: WorldCardRoot,
  Overlay: WorldCardRootOverlay,
  Content: WorldCardContent,
  Details: WorldCardDetails,
  Scaffolding: WorldCardScaffolding,
  Skeleton: WorldCardSkeleton,
  Connected: WorldCardConnected,
  Stats: WorldCardStats,
});

export default WorldCard;
