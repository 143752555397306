import NextLink from 'next/link';

import Link, { LinkProps } from 'components/base/Link';

type WarningTermsLinkProps = {
  variant?: LinkProps['variant'];
};

export default function WarningTermsLink(props: WarningTermsLinkProps) {
  return (
    <NextLink href="/terms" passHref>
      <Link
        css={{ color: 'inherit' }}
        target="_blank"
        variant={props.variant}
        hasUnderline
      >
        Terms of Service
      </Link>
    </NextLink>
  );
}
