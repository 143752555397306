import { ApiWorldRole } from 'gql/api/types-api.generated';

import { ApiWorldTeamUser, WorldStatsWithCreators } from 'types/World';

import { areKeysEqual } from './users';

type DynamicWorldStat = {
  type: 'live' | 'total';
  count: number;
  label: string;
};

type DynamicWorldStatTuple = [DynamicWorldStat, DynamicWorldStat];

const LIVE = 'live' as const;
const TOTAL = 'total' as const;

const LABELS = {
  availableNfts: 'Available NFTs',
  drops: 'Drops',
  editions: 'Editions',
  launches: 'Launches',
  liveAuctions: 'Live auctions',
  liveDrops: 'Live drops',
  liveEditions: 'Live editions',
  liveLaunches: 'Live launches',
  soldNfts: 'Sold NFTs',
};

export const getDynamicWorldStats = (
  stats: WorldStatsWithCreators
): DynamicWorldStatTuple => {
  const {
    availableNftCount,
    dropCount,
    editionCount,
    liveAuctionCount,
    liveDropCount,
    liveEditionCount,
    soldNftCount,
  } = stats;

  // A launch is a drop or an edition that's currently open to mint
  const launchesCount = dropCount + editionCount;
  const liveLaunchesCount = liveDropCount + liveEditionCount;

  const hasNfts = availableNftCount > 0;
  const hasDrops = dropCount > 0;
  const hasEditions = editionCount > 0;
  const hasLiveAuctions = hasNfts && liveAuctionCount > 0;
  const hasLiveDrops = hasDrops && liveDropCount > 0;
  const hasLiveEditions = hasEditions && liveEditionCount > 0;

  const liveLaunchesItem = {
    type: LIVE,
    count: liveLaunchesCount,
    label: LABELS.liveLaunches,
  };

  const liveDropsItem = {
    type: LIVE,
    count: liveDropCount,
    label: LABELS.liveDrops,
  };

  const liveEditionsItem = {
    type: LIVE,
    count: liveEditionCount,
    label: LABELS.liveEditions,
  };

  const liveAuctionsItem = {
    type: LIVE,
    count: liveAuctionCount,
    label: LABELS.liveAuctions,
  };

  const totalLaunchesItem = {
    type: TOTAL,
    count: launchesCount,
    label: LABELS.launches,
  };

  const totalDropsItem = {
    type: TOTAL,
    count: dropCount,
    label: LABELS.drops,
  };

  const totalEditionsItem = {
    type: TOTAL,
    count: editionCount,
    label: LABELS.editions,
  };

  const totalListingsItem = {
    type: TOTAL,
    count: availableNftCount,
    label: LABELS.availableNfts,
  };

  const totalSoldItem = {
    type: TOTAL,
    count: soldNftCount,
    label: LABELS.soldNfts,
  };

  // Live drops + editions + auctions
  if (hasLiveDrops && hasLiveEditions && hasLiveAuctions) {
    return [liveLaunchesItem, liveAuctionsItem];
  }

  // Live drops + editions
  if (hasLiveDrops && hasLiveEditions) {
    return [liveLaunchesItem, totalSoldItem];
  }

  // Live drops + auctions
  if (hasLiveDrops && hasLiveAuctions) {
    return [liveDropsItem, liveAuctionsItem];
  }

  // Live editions + auctions
  if (hasLiveEditions && hasLiveAuctions) {
    return [liveEditionsItem, liveAuctionsItem];
  }

  // Live drops
  if (hasLiveDrops) {
    return [liveDropsItem, totalSoldItem];
  }

  // Live editions
  if (hasLiveEditions) {
    return [liveEditionsItem, totalSoldItem];
  }

  // Live auctions
  if (hasLiveAuctions) {
    return [totalListingsItem, liveAuctionsItem];
  }

  // Only Drops + Editions
  if (!hasNfts && hasDrops && hasEditions) {
    return [totalLaunchesItem, totalSoldItem];
  }

  // Only Drops
  if (!hasNfts && hasDrops) {
    return [totalDropsItem, totalSoldItem];
  }

  // Only Editions
  if (!hasNfts && hasEditions) {
    return [totalEditionsItem, totalSoldItem];
  }

  return [totalListingsItem, totalSoldItem];
};

type GroupedWorldTeamUsers = { [key in ApiWorldRole]: ApiWorldTeamUser[] };

export const groupWorldTeamUsersByRole = (
  worldTeamUsers: ApiWorldTeamUser[]
): GroupedWorldTeamUsers => {
  const initialGroupedWorldTeamUsers: GroupedWorldTeamUsers = {
    ADMIN: [],
    EDITOR: [],
  };

  return worldTeamUsers.reduce((groupedUsers, user) => {
    return {
      ...groupedUsers,
      [user.role]: [...groupedUsers[user.role], user],
    };
  }, initialGroupedWorldTeamUsers);
};

export const filterAndSortTeamUsersByRole = <
  User extends Pick<ApiWorldTeamUser, 'role' | 'publicKey'>,
>(options: {
  teamUsers: User[];
  ownerPublicKey: string;
}): User[] => {
  const { teamUsers, ownerPublicKey } = options;

  return teamUsers
    .filter((user) => !areKeysEqual([user.publicKey, ownerPublicKey]))
    .sort((a, b) => {
      if (a.role === 'ADMIN' && b.role !== 'ADMIN') {
        return -1; // Move 'ADMIN' to the top
      } else if (a.role !== 'ADMIN' && b.role === 'ADMIN') {
        return 1; // Move 'ADMIN' to the top
      } else {
        // If roles are the same or both are not 'ADMIN', sort by publicKey
        return a.publicKey.localeCompare(b.publicKey);
      }
    });
};
