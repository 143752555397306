import { usePrivy } from '@privy-io/react-auth';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useDisconnect } from 'wagmi';

import { useAdminStore } from 'state/stores/admin';

import { DisconnectResponse, disconnectWalletSession } from 'lib/auth';
import { isAuthenticatedRoute } from 'utils/auth';

export default function useDisconnectWallet(
  options?: UseMutationOptions<DisconnectResponse, Error>
) {
  const { disconnect } = useDisconnect();
  const privy = usePrivy();
  const adminStore = useAdminStore();

  const router = useRouter();

  return useMutation({
    ...options,
    onSuccess: async () => {
      const isAuthRoute = isAuthenticatedRoute(router.pathname);
      if (isAuthRoute) {
        await router.push('/');
      }
    },
    mutationFn: async () => {
      disconnect();
      privy.logout();
      adminStore.setIsViewingAsAdmin(false);
      return await disconnectWalletSession();
    },
  });
}
