import { includes } from 'ramda';

export const isAuthenticatedRoute = (pathname: string): boolean => {
  const authenticatedRoutes = [
    '/admin/approve',
    '/create/mint/[id]',
    '/create/list/[contractAddress]/[tokenId]',
    '/creator/[contractAddress]/[tokenId]/tags',
    '/profile',
    '/notifications',
    '/settings',
    '/migrate-account',
    '/activity',
  ];

  return includes(pathname, authenticatedRoutes);
};
