import Tippy, { TippyProps } from '@tippyjs/react';
import { ReactNode, useState } from 'react';

import 'tippy.js/animations/shift-away.css';

import Box from 'components/base/Box';
import WorldCard from 'components/cards/worlds/WorldCard';

import usePopoverContainerRef from 'hooks/use-popover-container-ref';
import { DEFAULT_TIPPY_PROPS } from 'utils/popover';

import { WorldOverview } from 'types/World';

interface WorldHoverCardProps {
  children: ReactNode;
  world: WorldOverview;
  delay?: TippyProps['delay'];
  offset?: TippyProps['offset'];
  placement?: TippyProps['placement'];
}

export default function WorldHoverCard(props: WorldHoverCardProps) {
  const { children, delay, world, offset, placement } = props;

  const [shouldRender, setShouldRender] = useState(false);
  const popoverContainerRef = usePopoverContainerRef();

  return (
    <Tippy
      {...DEFAULT_TIPPY_PROPS}
      content={
        // Only render the popover content when the popover is triggered,
        // to avoid firing the queries inside this component before they are needed
        shouldRender ? (
          <WorldCard
            backgroundImageUrl={world.coverUrl ?? undefined}
            imageUrl={world.imageUrl ?? undefined}
            name={world.name}
            size="short"
            moderationStatus={world.moderationStatus}
          />
        ) : null
      }
      delay={delay || DEFAULT_TIPPY_PROPS.delay}
      offset={offset || DEFAULT_TIPPY_PROPS.offset}
      placement={placement || DEFAULT_TIPPY_PROPS.placement}
      interactive
      animation="shift-away"
      onTrigger={() => setShouldRender(true)}
      touch={false}
      appendTo={popoverContainerRef.current}
    >
      <Box css={{ cursor: 'pointer' }}>{children}</Box>
    </Tippy>
  );
}
