import { darkTheme, styled } from '@f8n-frontend/stitches';

const DarkMode = styled('div', {
  color: '$black100',
});

DarkMode.defaultProps = {
  className: darkTheme,
};

export default DarkMode;
