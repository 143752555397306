import { create } from 'zustand';

interface AdminState {
  isViewingAsAdmin: boolean;
  setIsViewingAsAdmin: (value: boolean) => void;
}

export const useAdminStore = create<AdminState>((set) => ({
  isViewingAsAdmin: false,
  setIsViewingAsAdmin: (value) => set({ isViewingAsAdmin: value }),
}));
