import { getPath } from 'utils/router';

import { DBProviderType } from 'types/Wallet';

interface WalletSessionArgs {
  token: string;
  connectorId: string;
}

export type AuthorizeResponse =
  | { done: true; token: string }
  | { done: false; token: null };

export async function connectWalletSession(
  args: WalletSessionArgs
): Promise<AuthorizeResponse> {
  const { token, connectorId } = args;

  const res = await fetch(getPath.api.authorize, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      connectorType: getConnectorType(connectorId),
    }),
  });

  const data = await res.json();
  return data;
}

export type DisconnectResponse = { done: true };

export async function disconnectWalletSession(): Promise<DisconnectResponse> {
  const res = await fetch(getPath.api.disconnect, {
    method: 'POST',
  });

  const data = await res.json();
  return data;
}

// We have to use different enums here as the the DB expects Metamask as the injected value
const getConnectorType = (connectorId: string): DBProviderType =>
  connectorId === 'metaMask' ? 'METAMASK' : 'WALLETCONNECT';
