import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-stats.generated';
import { mapApiExhibitionStatsToWorldStats } from 'schemas/parse/world';

import { WorldStatsWithCreators } from 'types/World';

export async function getWorldStats(
  variables: Generated.ApiWorldStatsVariables
): Promise<WorldStatsWithCreators> {
  const fetchExhibitionStats = Generated.useWorldStats.fetcher(variables);
  const data = await fetchExhibitionStats();
  return mapApiExhibitionStatsToWorldStats(data);
}

const getWorldStatsQueryKey = (variables: Generated.ApiWorldStatsVariables) => {
  return ['world-stats', variables];
};

export default function useWorldStats<Selected = WorldStatsWithCreators>(
  variables: Generated.ApiWorldStatsVariables,
  options: Pick<
    UseQueryOptions<
      WorldStatsWithCreators,
      Error,
      Selected,
      ReturnType<typeof getWorldStatsQueryKey>
    >,
    | 'enabled'
    | 'refetchOnMount'
    | 'refetchOnWindowFocus'
    | 'refetchOnReconnect'
    | 'staleTime'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: getWorldStatsQueryKey(variables),
    queryFn: () => getWorldStats(variables),
  });
}
