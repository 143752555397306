import DarkMode from './DarkMode';
import Box from './base/Box';

interface ThemeProps {
  children: React.ReactNode;
  theme?: 'dark' | 'light';
}

export default function Theme(props: ThemeProps) {
  const { children, theme = 'light' } = props;

  if (theme === 'dark') {
    return <DarkMode>{props.children}</DarkMode>;
  }

  // Empty div added here to match the DOM structure of DarkMode
  // TODO: add an explicit LightMode
  return <Box>{children}</Box>;
}
