import { styled } from '@f8n-frontend/stitches';

/**
 * Usually an image, but can be a video by applying an `as` prop.
 */
const BaseMedia = styled('img', {
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  backgroundColor: '$white100',
  borderRadius: 'inherit',
});

const MediaRoot = styled('div', {
  position: 'relative',
  // Allows `Media.Tint` to inherit the parent's backgroundColor
  backgroundColor: 'inherit',
});

const MediaTint = styled('div', {
  borderRadius: 'inherit',
  backgroundColor: 'inherit',

  /**
   * This adds a very subtle semi-opaque border on top of the media.
   * This is helpful when rendering dark media on a dark background, or white media on a white background.
   *
   * Without this outline, the media blends into the background.
   */
  '&:after': {
    content: '',
    position: 'absolute',
    inset: 0,
    borderRadius: 'inherit',
    pointerEvents: 'none',
  },

  variants: {
    edge: {
      darken: {
        '&:after': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$black100',
          opacity: 0.05,
        },
      },
      lighten: {
        '&:after': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$white100',
          opacity: 0.12,
        },
      },
    },
  },

  defaultVariants: {
    edge: 'lighten',
  },
});

const Media = Object.assign(BaseMedia, {
  /**
   * Used to position `Media.Tint`
   *
   * @example
   * <Media.Root css={{ height: 500, width: 500 }}>
   *   <Media.Tint>
   *     <Media css={{ opacity: 0.2 }} src="..." />
   *   </Media.Tint>
   * </Media.Root>
   */
  Root: MediaRoot,
  /**
   * Used as a wrapper for `Media` to provide a tinted background.
   *
   * @example
   * <Media.Tint>
   *   <Media css={{ opacity: 0.2 }} src="..." />
   * </Media.Tint>
   */
  Tint: MediaTint,
});

export default Media;
