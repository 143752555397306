import { cond } from 'ramda';
import React, { ReactNode } from 'react';

import Link from 'components/base/Link';
import WarningTermsLink from 'components/trust-safety/WarningTermsLink';

import { BasicArtwork } from 'types/Artwork';
import { ModeratedModerationStatus, ModerationStatus } from 'types/Moderation';

import { isValueInArray } from './helpers';
import { helpCenterPaths } from './router';

export const isFlaggedForModeration = (
  status?: ModerationStatus
): status is ModeratedModerationStatus => {
  if (!status) {
    return false;
  }

  return isValueInArray(status, [
    'SUSPENDED',
    'UNDER_REVIEW',
    'TAKEDOWN_REQUESTED',
  ]);
};

export const getArtworkModerationTitle = cond<ModerationStatus, string>([
  [(status) => status === 'UNDER_REVIEW', () => 'This NFT is under review'],
  [
    (status) => status === 'SUSPENDED',
    () => 'This NFT has been permanently removed.',
  ],
  [
    (status) => status === 'TAKEDOWN_REQUESTED',
    () => 'The NFT has received a DMCA takedown notice.',
  ],
]);

export const getArtworkModerationDescription = cond<BasicArtwork, ReactNode>([
  [
    (artwork) => artwork.moderationStatus === 'UNDER_REVIEW',
    () => (
      <>
        This NFT is currently under review by the Foundation team to ensure it
        has not violated the Foundation <WarningTermsLink />.
      </>
    ),
  ],
  [
    (artwork) => artwork.moderationStatus === 'SUSPENDED',
    () => (
      <>
        This NFT was found to be in violation of the Foundation{' '}
        <WarningTermsLink /> and has been permanently removed.
      </>
    ),
  ],
  [
    (artwork) => artwork.moderationStatus === 'TAKEDOWN_REQUESTED',
    (artwork) => (
      <>
        The NFT has received a DMCA takedown notice from{' '}
        {artwork?.moderationFrom}.{' '}
        <Link
          css={{ display: 'inline' }}
          hasUnderline
          href={helpCenterPaths.aboutDMCA}
          rel="noreferrer"
          target="_blank"
          variant="primary"
          weight="regular"
        >
          Learn about the DMCA process →
        </Link>
      </>
    ),
  ],
]);

// TODO: update to use a normal map, and handle TAKEDOWN_REQUESTED state
export const getUserModerationTitle = cond<ModerationStatus, string>([
  [(status) => status === 'UNDER_REVIEW', () => 'Your profile is under review'],
  [
    (status) => status === 'SUSPENDED',
    () => 'Your profile has been permanently removed.',
  ],
]);

// TODO: update to use a normal map, and handle TAKEDOWN_REQUESTED state
export const getUserModerationDescription = cond<ModerationStatus, ReactNode>([
  [
    (status) => status === 'UNDER_REVIEW',
    () => (
      <>
        Your profile has been found to be in violation of the Foundation{' '}
        <WarningTermsLink />.
      </>
    ),
  ],
  [
    (status) => status === 'SUSPENDED',
    () => (
      <>
        Your profile has been found to be in violation of the Foundation{' '}
        <WarningTermsLink /> and has been permanently suspended.
      </>
    ),
  ],
]);
