import { mapAvatarSizePropToImageSize } from 'utils/avatar';
import { optimizeAsset } from 'utils/imgix';

import Avatar, { AvatarVariants } from './Avatar';
import Skeleton from './Skeleton';

type WorldLogoProps = {
  imageUrl: string;
  size?: AvatarVariants['size'];
};

function WorldLogo(props: WorldLogoProps) {
  const { imageUrl, size } = props;

  const optimizedImageUrl = optimizeWorldLogoImageUrl(imageUrl, size);

  return <Avatar shape="square" imageUrl={optimizedImageUrl} size={size} />;
}

function optimizeWorldLogoImageUrl(url: string, size?: AvatarVariants['size']) {
  const imageSize = mapAvatarSizePropToImageSize(size);

  return optimizeAsset(url, {
    w: imageSize,
    h: imageSize,
    fit: 'crop',
    auto: undefined,
    dpr: 2,
  });
}

function WorldLogoSkeleton(props: Pick<WorldLogoProps, 'size'>) {
  const { size } = props;
  return <Skeleton.Avatar shape="square" size={size} />;
}

WorldLogo.Skeleton = WorldLogoSkeleton;
export default WorldLogo;
