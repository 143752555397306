import { useReadContract } from 'wagmi';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { FNDMiddleware, getAccountInfoV2 } from 'lib/abis/FNDMiddleware';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';

export default function useMarketBalances(variables: { chainId: ChainId }) {
  const auth = useAuth();

  return useReadContract({
    abi: FNDMiddleware,
    address: getContractAddress({
      chainId: variables.chainId,
      contractName: 'middleware',
    }),
    chainId: variables.chainId,
    functionName: 'getAccountInfoV2',
    args: hasPublicKey(auth) ? [auth.publicKey] : undefined,
    query: {
      enabled: hasPublicKey(auth),
      select: getAccountInfoV2.parseOutput,
    },
  });
}
