import { ENV } from './env';

export const absoluteUrl = (path: string): string => {
  const rootUrl = ENV.APP_URL;

  if (!path || path === '/') {
    return rootUrl;
  }

  return rootUrl + path;
};
